/*=============================================
	=              THEME DEFAULT             =
=============================================*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
	font-size: 15px;
	color: #fff;
	font-style: normal;
}
img,
.img {
	max-width: 100%;
	transition: all 0.3s ease-out 0s;
}
.f-left {
	float: left
}
.f-right {
	float: right
}
.fix {
	overflow: hidden
}
a,
button {
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	text-decoration: none;
}
a:focus,
.btn:focus,
button:focus {
	text-decoration: none;
	outline: none;
	box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
	color: #2B96CC;
	text-decoration: none;
}
a,
button {
	color: #1696e7;
	outline: medium none;
	text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
	outline: 0
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', sans-serif;
	color: #fff;
	margin-top: 0px;
	font-style: normal;
	font-weight: 700;
	text-transform: capitalize;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
h1 {
	font-size: 40px;
}
h2 {
	font-size: 35px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none
}
p {
	font-size: 16px;
	font-weight: normal;
	line-height: 28px;
	color: #d0d0d0;
	margin-bottom: 15px;
}
hr {
	border-bottom: 1px solid #eceff8;
	border-top: 0 none;
	margin: 30px 0;
	padding: 0;
}
label {
	color: #d0d0d0;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
.theme-overlay {
	position: relative
}
.theme-overlay::before {
	background: #2081e2 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
}
.separator {
	border-top: 1px solid #f2f2f2
}

/*=============================================
	=          BUTTON STYLE          =
=============================================*/
.btn {
	user-select: none;
	-moz-user-select: none;
	background: #d05b75 none repeat scroll 0 0;
	border: medium none;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 1;
	margin-bottom: 0;
	padding: 20px 34px;
	text-align: center;
	text-transform: capitalize;
	touch-action: manipulation;
	transition: all 0.3s ease 0s;
	vertical-align: middle;
	white-space: break-spaces;
	font-family: 'Poppins', sans-serif;
	position: relative;
	z-index: 1;
	border-radius: 30px;
}
.btn:hover {
    color: #ffffff;
    background: #18203e;
}

.breadcrumb > .active {
	color: #888;
}
